import { render, staticRenderFns } from "./ComingSoon.vue?vue&type=template&id=481f498a&scoped=true"
import script from "./ComingSoon.vue?vue&type=script&lang=js"
export * from "./ComingSoon.vue?vue&type=script&lang=js"
import style0 from "./ComingSoon.vue?vue&type=style&index=0&id=481f498a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481f498a",
  null
  
)

export default component.exports