import { render, staticRenderFns } from "./HowToMakeDeposit.vue?vue&type=template&id=16beb2f0&scoped=true"
import script from "./HowToMakeDeposit.vue?vue&type=script&lang=js"
export * from "./HowToMakeDeposit.vue?vue&type=script&lang=js"
import style0 from "./HowToMakeDeposit.vue?vue&type=style&index=0&id=16beb2f0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16beb2f0",
  null
  
)

export default component.exports