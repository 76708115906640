<template>
  <div class="chart-item open-interest-chart">
    <h2 class="trading-title">{{ $t('Open Interest') }}</h2>
    <div class="chart-item-container">
      <div class="chart-item-filter-item">
        <span class="report-filter-item-title">{{ $t('type') }}</span>
        <CustomSelect
            custom-class="support-form"
            :options="filterOptionsList"
            default="BTC/USDT"
            :modelValue="filter"
            @update:modelValue="chooseValueType($event)"
        />
      </div>
      <time-frame
          :periods="periods"
          :dimension="dimension"
          @selectedPeriod="choosePeriod"
      >
      </time-frame>
    </div>
    <div class="highcharts-wrapper">
      <div class="preloader new-preloader" v-if="isLoading">
        <lottie-animation
            ref="preloader"
            :animationData="require('@/assets/img/Loader.json')"
            :loop="true"
            :autoPlay="true"
            :speed="1"
        />
      </div>
      <highcharts
          v-else-if="takerVolumeData.length"
          :updateArgs="updateArgs"
          :options="chartOptionsOpenInterest"
      />
      <div v-else class="report-empty-list">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M42.1351 5.89334C41.0418 4.8 39.1484 5.54667 39.1484 7.06667V16.3733C39.1484 20.2667 42.4551 23.4933 46.4818 23.4933C49.0151 23.52 52.5351 23.52 55.5484 23.52C57.0684 23.52 57.8684 21.7333 56.8018 20.6667C52.9618 16.8 46.0818 9.84 42.1351 5.89334Z" fill="#92939C"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M46.9613 27.1733H54.668C56.1346 27.1733 57.3346 28.3733 57.3346 29.84V39.6441C55.2687 38.1884 52.7495 37.3333 50.0315 37.3333C43.0218 37.3333 37.3346 43.0205 37.3346 50.0302C37.3346 53.365 38.6218 56.4005 40.7262 58.6666H21.5213C13.308 58.6666 6.66797 53.3333 6.66797 43.8133V20.1866C6.66797 10.6666 13.308 5.33331 21.5213 5.33331H32.828C34.2946 5.33331 35.4946 6.53331 35.4946 7.99998V15.7066C35.4946 22.0266 40.6413 27.1733 46.9613 27.1733ZM57.3346 43.755V43.8133C57.3346 52.1992 52.1825 57.3365 45.3411 58.4415C42.3948 56.7941 40.4015 53.6436 40.4015 50.0302C40.4015 44.7143 44.7156 40.4002 50.0315 40.4002C52.9509 40.4002 55.5682 41.7013 57.3346 43.755ZM20.0013 47.3333H30.668C31.7613 47.3333 32.668 46.4266 32.668 45.3333C32.668 44.24 31.7613 43.3333 30.668 43.3333H20.0013C18.908 43.3333 18.0013 44.24 18.0013 45.3333C18.0013 46.4266 18.908 47.3333 20.0013 47.3333ZM20.0013 36.6666H36.0013C37.0946 36.6666 38.0013 35.76 38.0013 34.6666C38.0013 33.5733 37.0946 32.6666 36.0013 32.6666H20.0013C18.908 32.6666 18.0013 33.5733 18.0013 34.6666C18.0013 35.76 18.908 36.6666 20.0013 36.6666Z" fill="#92939C"/>
          <path d="M59.9855 57.2476L58.0636 55.3257C59.0655 53.8127 59.6584 51.993 59.6584 50.0302C59.6584 44.7143 55.3443 40.4002 50.0284 40.4002C44.7125 40.4002 40.3984 44.7143 40.3984 50.0302C40.3984 55.3461 44.7125 59.6602 50.0284 59.6602C51.9912 59.6602 53.7905 59.0672 55.3239 58.0654L57.2458 59.9873C57.6343 60.3758 58.125 60.5598 58.6361 60.5598C59.1473 60.5598 59.638 60.3758 60.0264 59.9873C60.742 59.2513 60.742 58.0245 59.9855 57.2476Z" fill="#92939C"/>
        </svg>
        <p class="secondary-text holder-not-logged-text">{{ $t('No records found') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LottieAnimation from 'lottie-web-vue'
import CustomSelect from '@/components/inputs/CustomSelect';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import moment from 'moment';
import numeral from 'numeral';
import TimeFrame from '@/components/time-frame/TimeFrame';

export default {
  data() {
    return {
      Highcharts,
      dimension: 'MIN_5',
      type: 'btc_usdt',
      filter: null,
      scaleNameCoin: '',
      isLoading: false,
      scaleNameCurrency: '',
      periods: [],
      openInterestDataTime: [],
      openInterestDataCoin: [],
      openInterestDataCurrency: [],
      chartOptionsOpenInterestDataCoin: [],
      takerVolumeData: [],
      updateArgs: [true, true, true],
      filterOptionsList: [
        {
          name: 'BTC/USDT',
          value: 'btc_usdt',
        },
        {
          name: 'ETH/USDT',
          value: 'eth_usdt',
        },
        {
          name: 'SOL/USDT',
          value: 'sol_usdt',
        },
        {
          name: 'ADA/USDT',
          value: 'ada_usdt',
        },
        {
          name: 'NEAR/USDT',
          value: 'near_usdt',
        },
        {
          name: 'APE/USDT',
          value: 'ape_usdt',
        },
        {
          name: 'ATOM/USDT',
          value: 'atom_usdt',
        },
        {
          name: 'AVAX/USDT',
          value: 'avax_usdt',
        },
        {
          name: 'DOT/USDT',
          value: 'dot_usdt',
        },
        {
          name: 'GMT/USDT',
          value: 'gmt_usdt',
        },
        {
          name: 'MATIC/USDT',
          value: 'matic_usdt',
        },
      ],
      chartOptionsOpenInterest: {
        title: null,
        credits: {
          enabled: false
        },
        chart: {
          backgroundColor: this.$vuetify.theme.dark,
          height: 550,
          zoomType: 'x',
          marginTop: 70,
          marginBottom: 120,
        },
        xAxis: [{
          // categories: [],
          labels: {
            step: 3,
            style: {
              'color': '#92939c',
              'cursor': 'pointer',
              'fontSize': '11px',
              'lineHeight': '16px',
              'fontWeight': '400',
              'textOverflow': 'ellipsis'
            },
            formatter: function () {
              return moment.utc(this.value).format('HH:mm') + '<br>' +  moment.utc(this.value).format('MM/DD');
            }
          },
          lineColor: 'var(--v-graphBottomLine-base)',
        }],
        plotOptions: {
          areaspline: {
            fillOpacity: 0.5,
          },
        },
        tooltip: {
          shared: true,
          // xDateFormat: '%Y-%m-%d',
          headerFormat: '{point.x:%e %b %H:%M}<br>',
          pointFormat: '{series.name}: <b>{point.y:.2f}</b><br/>',
        },
        yAxis: [
          {
            gridLineColor: 'var(--v-graphBackgroundLineColor-base)',
            lineColor: 'var(--v-graphBackgroundLineColor-base)',
            minorGridLineColor: 'var(--v-graphBackgroundLineColor-base)',
            tickColor: 'var(--v-graphBackgroundLineColor-base)',
            plotLines: [{
              color: 'var(--v-graphBackgroundLineColor-base)'
            }],
            id: 'y-axis',
            title: {
              align: 'high',
              offset: 0,
              rotation: 0,
              y: -35,
              x: -15,
              text: this.scaleNameCoin,
              style: {
                'color': 'var(--v-selectColor-base)',
                'cursor': 'pointer',
                'fontSize': '14px',
                'lineHeight': '21px',
                'fontWeight': '600',
                'textOverflow': 'ellipsis'
              },
            },
            labels: {
              style: {
                'color': '#92939c',
                'cursor': 'pointer',
                'fontSize': '11px',
                'lineHeight': '16px',
                'fontWeight': '400',
                'textOverflow': 'ellipsis'
              },
              formatter: function () {
                return numeral(this.value).format('0.0a');
              }
            },
          },
          {
            gridLineColor: 'var(--v-graphBackgroundLineColor-base)',
            lineColor: 'var(--v-graphBackgroundLineColor-base)',
            minorGridLineColor: 'var(--v-graphBackgroundLineColor-base)',
            tickColor: 'var(--v-graphBackgroundLineColor-base)',
            plotLines: [{
              color: 'var(--v-graphBackgroundLineColor-base)'
            }],
            id: 'y-axis1',
            title: {
              align: 'high',
              offset: 0,
              rotation: 0,
              y: -35,
              x: 15,
              text: this.scaleNameCurrency,
              style: {
                'color': 'var(--v-selectColor-base)',
                'cursor': 'pointer',
                'fontSize': '14px',
                'lineHeight': '21px',
                'fontWeight': '600',
                'textOverflow': 'ellipsis'
              },
            },
            opposite: true,
            labels: {
              style: {
                'color': '#92939c',
                'cursor': 'pointer',
                'fontSize': '11px',
                'lineHeight': '16px',
                'fontWeight': '400',
                'textOverflow': 'ellipsis'
              },
              formatter: function () {
                return numeral(this.value).format('0.0a');
              }
            },
          }
        ],
        legend: {
          align: 'center',
          itemStyle: {
            'color': 'var(--v-selectColor-base)',
            'cursor': 'pointer',
            'fontSize': '12px',
            'lineHeight': '18px',
            'fontWeight': 'normal',
            'textOverflow': 'ellipsis'
          },
          itemHoverStyle: {
            'color': '#42E8E0',
            'cursor': 'pointer',
            'fontSize': '12px',
            'lineHeight': '18px',
            'fontWeight': 'normal',
            'textOverflow': 'ellipsis'
          },
          squareSymbol: true,
          symbolHeight: 14,
          symbolWidth: 14,
          symbolRadius: 0,
          labelFormatter: function () {
            if (this.index !== 2) {
              return this.name + ' %';
            } else {
              return this.name;
            }
          }
        },
        series: [
          {
            yAxis: 'y-axis',
            type: 'column',
            color: '#42E8E0',
            tooltip: {
              valueSuffix: ''
            },
            borderColor: 'transparent',
            data: this.openInterestDataCoin,
            name: 'Open Interest',
          },
          {
            yAxis: 'y-axis1',
            type: 'areaspline',
            color: 'var(--v-graphLineColor-base)',
            // fillColor: 'linear-gradient(184.47deg, rgba(136, 255, 243, 0.61) 3.47%, rgba(136, 255, 243, 0) 96.23%)',
            // fillColor: 'rgba(136, 255, 243, 0.61)',
            fillColor: {
              linearGradient : {
                x1: 1,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops : [
                [0, Highcharts.Color('#88fff3').setOpacity(0.61).get('rgba')],
                [1, Highcharts.Color('#88fff3').setOpacity(0).get('rgba')],
              ]
            },
            tooltip: {
              valueSuffix: ''
            },
            data: this.openInterestDataCurrency,
            marker: {
              fillColor: 'white',
              lineWidth: 2,
              lineColor: 'var(--v-graphLineColor-base)',
            },
            stickyTracking: false,
            name: 'Notional Value of Open Interest'
          },
        ]
      },
    };
  },
  components: {
    TimeFrame,
    LottieAnimation,
    highcharts: Chart,
    CustomSelect
  },
  methods: {
    ...mapActions({
      getOpenInterestData: 'tradingData/getOpenInterest',
    }),

    max(list) {
      if(list.length) {
        return list.reduce((a, b) => parseInt(a) > parseInt(b) ? a : b);
      }
    },

    min(list) {
      if(list.length) {
        return list.reduce((a, b) => parseInt(a) < parseInt(b) ? a : b);
      }
    },


    setPeriodFrame() {
      this.periods = [
        {
          buttonText: '5' + this.$t('minutes'),
          dimension: 'MIN_5'
        },
        {
          buttonText: '15' + this.$t('minutes'),
          dimension: 'MIN_15'
        },
        {
          buttonText: '30' + this.$t('minutes'),
          dimension: 'MIN_30'
        },
        {
          buttonText: '1' + this.$t('hours'),
          dimension: 'HOUR_1'
        },
        {
          buttonText: '4' + this.$t('hours'),
          dimension: 'HOUR_4'
        },
        {
          buttonText: '1' + this.$t('day'),
          dimension: 'DAY_1'
        },
        // {
        //   buttonText: '1' + this.$t('months'),
        //   dimension: 'MONTH_1'
        // },
      ];
    },

    choosePeriod(dimension) {
      this.dimension = dimension;
      this.getOpenInterest();
    },

    chooseValueType(type) {
      this.type = type;
      this.getOpenInterest();
    },

    getOpenInterest() {
      this.isLoading = true;
      this.openInterestDataTime = [];
      this.openInterestDataCoin = [];
      this.openInterestDataCurrency = [];

      this.getOpenInterestData({
        symbol: this.type,
        dimension: this.dimension
      }).then(res => {
        const body = res;
        if(body.length) {
          this.takerVolumeData = body;

          body.forEach(el => {
            this.openInterestDataTime.push(el.time);
            this.openInterestDataCoin.push([el.openInterest]);
            this.openInterestDataCurrency.push([el.notionalValue]);
          });

          this.chartOptionsOpenInterest.xAxis[0].categories = this.openInterestDataTime;

          this.chartOptionsOpenInterest.series[0].data = this.openInterestDataCoin;
          this.chartOptionsOpenInterest.series[1].data = this.openInterestDataCurrency;

          this.chartOptionsOpenInterest.yAxis[0].title.text = ` ${this.type.split('_')[0].toUpperCase()}`;
          this.chartOptionsOpenInterest.yAxis[1].title.text = ` ${this.type.split('_')[1].toUpperCase()}`;
          this.chartOptionsOpenInterest.series[0].name = this.$t('Open Interest');
          this.chartOptionsOpenInterest.series[1].name = this.$t('Notional Value of Open Interest');
          this.chartOptionsOpenInterest.series[0].tooltip.valueSuffix = ` ${this.type.split('_')[0].toUpperCase()}`;
          this.chartOptionsOpenInterest.series[1].tooltip.valueSuffix = ` ${this.type.split('_')[1].toUpperCase()}`;
        }else {
          this.takerVolumeData = []
        }

        this.isLoading = false;
      });
    }
  },
  watch: {
    '$i18n.locale'() {
      this.getOpenInterest();
      this.setPeriodFrame();
    },
  },
  mounted() {
    this.getOpenInterest();
    this.setPeriodFrame();
  }
};
</script>

<style scoped>
.chart-item-container {
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 40px;
  font-size: 20px;
}

.chart-item-container > div {
  position: relative;
  width: 200px;
}

.chart-item-container .chart-item-filter-item {
  position: relative;
  width: 240px;
}

.report-filter-item-title {
  position: absolute;
  top: -25px;
  left: 0;
}

.highcharts-wrapper {
  position: relative;
  min-height: 500px;
}

.preloader-overlay {
  background-color: transparent;
}

.holder-not-logged-text {
  color: #A4A5AF;
}
</style>
